<template>
  <div>
      <section v-if="editor" class="buttons p-1"
          style="border-top: 2px solid #D8D6DE;border-left: 2px solid #D8D6DE;border-right: 2px solid #D8D6DE;border-radius: 8px 8px 0 0;gap: 4px;display: flex;flex-wrap: wrap;">
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleBold().run()"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              :class="{ 'bg-primary': editor.isActive('bold') }">
              <feather-icon :color="editor.isActive('bold') ? 'white' : ''" icon="BoldIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleItalic().run()"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              :class="{ 'bg-primary': editor.isActive('italic') }">
              <feather-icon :color="editor.isActive('italic') ? 'white' : ''" icon="ItalicIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleCode().run()"
              :disabled="!editor.can().chain().focus().toggleCode().run()"
              :class="{ 'bg-primary': editor.isActive('code') }">
              <feather-icon :color="editor.isActive('code') ? 'white' : ''" icon="CodeIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="$bvModal.show(`set-link-${order}`)"
              :disabled="editor.isActive('link')" :class="{ 'bg-primary': editor.isActive('link') }">
              <feather-icon :color="editor.isActive('link') ? 'white' : ''" icon="LinkIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().unsetLink().run()"
              :disabled="!editor.isActive('link')">
              <img src="/icons/unset-link.svg" style="width: 20px;" alt="unset-link">
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setParagraph().run()"
              :class="{ 'bg-primary': editor.isActive('paragraph') }">
              <feather-icon :color="editor.isActive('paragraph') ? 'white' : ''" icon="TypeIcon" size="20" />
          </button>
          <button class="badge-pill border-0 d-flex justify-content-center align-items-center">
              <input class="border-0" style="margin: 0 4px;" type="color"
                  @input="editor.chain().focus().setColor($event.target.value).run()"
                  :value="editor.getAttributes('textStyle').color">
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().unsetColor().run()">
              حذف رنگ
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().clearNodes().run()">
              <feather-icon icon="XCircleIcon" size="20" />
          </button>
          <b-form-select class="badge-pill border-0 text-black" v-model="selectedFontSize" :options="fontSizeOptions"
              @change="changeFontSize" style="width: 100px;background-color: #f0f0f0;color: #000;"
              :style="!selectedFontSize ? '' : 'font-family: Arial, Helvetica, sans-serif;'" />
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 1 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h1
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 2 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h2
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 3 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h3
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 4 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h4
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 5 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h5
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
              :class="{ 'bg-primary text-white': editor.isActive('heading', { level: 6 }) }"
              style="font-size: 20px;font-family: Arial, Helvetica, sans-serif;">
              h6
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleBulletList().run()"
              :class="{ 'bg-primary': editor.isActive('bulletList') }">
              <feather-icon :color="editor.isActive('bulletList') ? 'white' : ''" icon="ListIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().toggleOrderedList().run()"
              :class="{ 'bg-primary': editor.isActive('orderedList') }">
              <feather-icon :color="editor.isActive('orderedList') ? 'white' : ''" icon="MenuIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setTextAlign('center').run()"
              :class="{ 'bg-primary': editor.isActive({ textAlign: 'center' }) }">
              <feather-icon :color="editor.isActive({ textAlign: 'center' }) ? 'white' : ''" icon="AlignCenterIcon"
                  size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setTextAlign('justify').run()"
              :class="{ 'bg-primary': editor.isActive({ textAlign: 'justify' }) }">
              <feather-icon :color="editor.isActive({ textAlign: 'justify' }) ? 'white' : ''" icon="AlignJustifyIcon"
                  size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setTextAlign('left').run()"
              :class="{ 'bg-primary': editor.isActive({ textAlign: 'left' }) }">
              <feather-icon :color="editor.isActive({ textAlign: 'left' }) ? 'white' : ''" icon="AlignLeftIcon"
                  size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setTextAlign('right').run()"
              :class="{ 'bg-primary': editor.isActive({ textAlign: 'right' }) }">
              <feather-icon :color="editor.isActive({ textAlign: 'right' }) ? 'white' : ''" icon="AlignRightIcon"
                  size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setHorizontalRule().run()">
              <feather-icon icon="MinusIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().setHardBreak().run()">
              <feather-icon icon="CornerDownLeftIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().undo().run()"
              :disabled="!editor.can().chain().focus().undo().run()">
              <feather-icon icon="CornerUpLeftIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="editor.chain().focus().redo().run()"
              :disabled="!editor.can().chain().focus().redo().run()">
              <feather-icon icon="CornerUpRightIcon" size="20" />
          </button>
          <button class="badge-pill border-0" @click="$bvModal.show(`modal-insert-uploader-${order}`)"
              variant="outline-primary">
              درج تصویر
          </button>
          <button class="badge-pill border-0" @click="$bvModal.show(`modal-image-uploader-${order}`)"
              variant="outline-primary">
              آپلود تصویر
          </button>
          <!-- <button class="badge-pill border-0" v-b-modal.modal-video variant="outline-primary">
      درج ویدیو
    </button> -->
      </section>
      <editor-content :editor="editor" dir="rtl" />
      <!-- image-uploader modal -->
      <b-modal :id="'modal-image-uploader-' + order" centered title="آپلود تصویر">
          <div v-if="isLoading" class="d-flex justify-content-center">
              <b-spinner style="width: 3rem; height: 3rem" label="Loading..." variant="primary" />
          </div>
          <div v-else>
              <b-form-group label="تصویر" label-for="basicInput">
                  <div class="custom-file">
                      <input type="file" class="custom-file-input" id="inputGroupFileOne" ref="file"
                          placeholder="فایل خود را انتخاب کنید..." accept=".jpg, .jpeg, .png" @change="onChange()">
                      <label class="custom-file-label" for="inputGroupFileOne">
                          {{ files[0] ? files[0].name : 'فایل خود را انتخاب کنید...' }}
                      </label>
                  </div>
              </b-form-group>

              <b-form-group label="alt" label-for="altInput">
                  <b-form-input id="altInput" placeholder="alt تصویر" v-model="imageAlt" />
              </b-form-group>


              <b-form-group label="عنوان" label-for="titleInput">
                  <b-form-input id="titleInput" placeholder="عنوان تصویر" v-model="imageTitle" />
              </b-form-group>
          </div>

          <template #modal-footer>
              <b-button :variant="isLoading ? 'secondary' : 'primary'" :disabled="isLoading"
                  @click="attachmentUpload">
                  آپلود
              </b-button>
          </template>
      </b-modal>
      <!-- image-insert modal -->
      <b-modal :id="'modal-insert-uploader-' + order" centered title="درج تصویر">
          <b-form-group label="لینک" label-for="linkInput">
              <b-form-input id="linkInput" placeholder="لینک تصویر" v-model="imageLink" />
          </b-form-group>
          <template #modal-footer>
              <b-button @click="addImage" variant="primary">
                  درج لینک
              </b-button>
          </template>
      </b-modal>
      <!-- video modal -->
      <!-- <b-modal :id="'modal-video-'+order" centered title="درج ویدیو آپارات">
    <b-form-group label="لینک" label-for="videoLinkInput">
      <b-form-input id="videoLinkInput" placeholder="لینک ویدیو" v-model="videoLink" />
    </b-form-group>
    <template #modal-footer>
      <b-button @click="addVideo" variant="primary">
        درج لینک
      </b-button>
    </template>
  </b-modal> -->
      <!-- set-link modal -->
      <b-modal :ref="'set-link-' + order" :id="'set-link-' + order" centered title="درج لینک">
          <b-form-group label="لینک" label-for="setLinkInput">
              <b-form-input id="setLinkInput" placeholder="لینک" v-model="linkValue" />
          </b-form-group>
          <template #modal-footer>
              <b-button @click="setLink" variant="primary">
                  درج لینک
              </b-button>
          </template>
      </b-modal>
  </div>
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
// import Image from './tiptap/extension-image'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import { BFormGroup, BFormInput, BSpinner, BButton, BFormSelect, } from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import axios from "axios";
// import interact from 'interactjs';
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from 'tiptap-extension-font-size'

export default {
  components: {
      EditorContent,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BSpinner,
      BButton,
  },
  props: {
      value: {
          type: String,
          default: '',
      },
      order: {
          type: Number,
          required: true,
      },
  },
  data() {
      return {
          editor: null,
          files: [],
          file: '',
          imageAlt: '',
          imageTitle: '',
          imageLink: '',
          linkValue: '',
          // videoLink: '',
          tempAttachmentId: null,
          ToastificationContent,
          LoadingComponent,
          isLoading: false,
          selectedFontSize: null,
          fontSizeOptions: [
              { value: null, text: 'اندازه متن', disabled: true },
              { value: '12px', text: '12pt' },
              { value: '14px', text: '14pt' },
              { value: '16px', text: '16pt' },
              { value: '18px', text: '18pt' },
              { value: '20px', text: '20pt' },
              { value: '22px', text: '22pt' },
              { value: '24px', text: '24pt' },
              { value: '26px', text: '26pt' },
              { value: '28px', text: '28pt' },
              { value: '36px', text: '36pt' },
              { value: '48px', text: '48pt' },
              { value: '72px', text: '72pt' },
          ],
      }
  },
  watch: {
      value(value) {
          // HTML
          const isSame = this.editor.getHTML() === value
          if (isSame) {
              return
          }
          this.editor.commands.setContent(value, false)
      },
  },
  methods: {
      addImage() {
          if (this.imageLink) {
              this.editor.chain().focus().setImage({ src: this.imageLink }).run()
          }
      },
      // addVideo() {
      //   if (this.videoLink) {
      //     this.editor.commands.insertContent('<h1>hello world</h1>')
      //   }
      // },
      onChange() {
          if (this.$refs.file.files[0].size / (1024 * 1024) > 5) {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "اخطار",
                      icon: "XOctagonIcon",
                      text: "حجم عکس انتخاب شده بیش از حد مجاز است. (5MB)",
                  },
              });
          } else {
              this.files = [...this.$refs.file.files];
          }
      },
      attachmentUpload() {
          this.isLoading = true
          const file = new FormData();
          if (this.files[0]) {
              file.append("file", this.files[0]);
              axios.post("/api/v1/admin/files/upload", file).then((response) => {
                  if (response.status == 201) {
                      this.files = []
                      if (response.data.data.url) {
                          this.editor.commands.setImage({ src: response.data.data.url, alt: this.imageAlt, title: this.imageTitle })
                          this.imageAlt = ''
                          this.imageTitle = ''
                      }
                      this.$bvModal.msgBoxConfirm('تصویر  با موفقیت آپلود شد', {
                          footerClass: "hidden",
                          title: 'تصویر آپلود شد',
                          size: 'sm',
                          hideHeaderClose: false,
                          modalClass: "modal-success",
                          centered: true,
                      })
                      this.isLoading = false
                  }
              }).catch((error) => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "اخطار",
                          icon: "XOctagonIcon",
                          variant: "danger",
                          text: "فایل بارگذازی نشد. لطفا دوباره تلاش کنید.",
                      },
                  });
                  this.isLoading = false
              })
          }
      },
      // addIframe() {
      //   const url = window.prompt('URL')
      //   if (url) {
      //     // this.editor.chain().focus().setIframe({ src: url }).run()
      //     this.editor.commands.insertContent(`<iframe src="${url}"  allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>`)
      //   }
      // },
      setLink() {
          // const previousUrl = this.editor.getAttributes('link').href
          const url = this.linkValue

          // cancelled
          if (url === null) {
              return
          }

          // empty
          if (url === '') {
              this.editor
                  .chain()
                  .focus()
                  .extendMarkRange('link')
                  .unsetLink()
                  .run()

              return
          }

          // update link
          this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .run()

          this.$refs[`set-link-${this.order}`].hide()
      },
      // handleImageResize(event) {
      // console.log(this.value = this.value + " ");
      // console.log(this.value = this.value - " ");

      // let tempEditorHtml = this.editor.getHTML()

      // console.log(tempEditorHtml + '.');
      // this.$emit('input', tempEditorHtml)

      // console.log(tempEditorHtml.substring(0, tempEditorHtml.length - 1));
      // this.$emit('input', tempEditorHtml)




      // Handle image resize logic here
      // You can use the event to get the dragged distance and update the image size
      // const target = event.target;
      // const x = parseFloat(target.getAttribute('data-x')) || 0;
      // const y = parseFloat(target.getAttribute('data-y')) || 0;

      // target.style.width = event.rect.width + 'px';
      // target.style.height = event.rect.height + 'px';

      // target.width = event.rect.width;
      // target.height = event.rect.height;
      // target.setAttribute('alt', 'new alt')
      // target.alt = 'new alt'

      // target.setAttribute('data-x', x + event.deltaRect.left);
      // target.setAttribute('data-y', y + event.deltaRect.top);

      // target.setAttribute('width', event.rect.width);
      // target.setAttribute('height', event.rect.height);

      // console.log(this.editor.getHTML());
      // console.log(target.setAttribute('hoy', 'target.src'));
      // console.log(this.editor.getText(), 'xxxxxxxxxxxxxxxxxxx');

      // let newContent = document.getElementsByClassName('tiptap')[0].innerHTML.toString()

      // console.log(newContent);

      // this.$emit('tiptapInput', newContent)
      // this.editor.commands.setContent(newContent, true)
      // console.log(typeof this.editor.getHTML());
      // console.log(typeof newContent);
      // const isSame = this.editor.getHTML() === newContent
      // if (isSame) {
      // return
      // }
      // this.editor.commands.setContent(newContent, false)
      // },

      // handleImageMousedown(event) {
      //   // Prevent default mousedown behavior to disable dragging
      //   event.preventDefault();
      // },
      changeFontSize() {
          if (this.selectedFontSize) {
              this.editor.chain().focus().setFontSize(this.selectedFontSize).run()
          }
      },
  },
  mounted() {
      this.editor = new Editor({
          content: this.value,
          editorProps: {
              attributes: {
                  class: 'p-1 editor-content-border',
              },
              transformPastedText(text) {
                  return text
              }
          },
          extensions: [
              StarterKit,
              Image.configure({
                  inline: true,
                  HTMLAttributes: {
                      // width: '300',
                      class: 'inline-flex mx-auto object-cover',
                  },
              }),
              TextAlign.configure({
                  defaultAlignment: 'right',
                  types: ['heading', 'paragraph'],
                  alignments: ['left', 'center', 'right', 'justify'],
              }),
              Link.configure({
                  openOnClick: false,
              }),
              TextStyle,
              FontSize,
              Color.configure({
                  types: ['textStyle'],
              }),
          ],
          onUpdate: () => {
              this.$emit('input', this.editor.getHTML())

              // interact.js for image resizing after editor update
              const images = this.$el.querySelectorAll('img');

              // uncomment it when you want to resize the image
              // remove dragging from editor
              // images.forEach((img) => {
              //   img.addEventListener('mousedown', this.handleImageMousedown);
              // });

              // interact.js for image resize
              // images.forEach((img) => {
              //   interact(img).resizable({
              //     edges: { left: true, right: true, bottom: true, top: true },
              //     draggable: false, // disable dragging from interact.js
              //     onmove: this.handleImageResize,
              //   }).on('resizestart', () => img.classList.add('resizable-image'))
              //     .on('resizeend', () => img.classList.remove('resizable-image'));
              // });
          },
      })
  },
  beforeDestroy() {
      this.editor.destroy()
  },
}
</script>
<style lang="scss">
// .resizable-image {
//   transition: width 0.3s, height 0.3s;
//   /* adjust the transition duration */
// }

.editor-content-border {
  border-radius: 0 0 8px 8px;
  border: 2px solid #D8D6DE !important;
}

/* Basic editor styles */
.tiptap {
  >*+* {
      margin-top: 0.75em;
  }

  img {
      max-width: 100%;
      height: auto;

      &.ProseMirror-selectednode {
          outline: 3px solid #68CEF8;
      }
  }
}

.ProseMirror {
  outline: 1px solid;
}
</style>